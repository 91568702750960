@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;400&display=swap');

html, body, #root {
  margin: 0;
  height: 100vh;
  overflow: auto;
}

#root {
  display: flex;
  place-content: stretch;
}

.authorization-background {
  max-width: 100%;
  max-height: 200px;
  z-index: 100;

  &.absolute {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.image-lt-md {
  max-height: 40px;
  max-width: 120px;
}


.image-gt-md {
  max-height: 120px;
  max-width: 450px;
}

.hide {
  display: none;
}